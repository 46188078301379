import React from "react";
import { makeStyles } from "@material-ui/core";

interface Props {
  name: string;
  message: string;
  url?: string;
  id: number;
}

const Result: React.FC<Props> = ({ name, message, url, id }) => {
  const classes = useStyles();

  // Function to handle click and store scroll position
  const handleClick = () => {
    sessionStorage.setItem("donationFinderScrollPosition", window.scrollY.toString());
  };

  return (
    <div>
      <a
        className={classes.anchorStyle}
        href={`https://prints.richardburnish.org/print/${id}`}
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <p className={classes.textName}>{name}</p>
            <p className={classes.textMessage}>{message}</p>
            {url && <img className={classes.image} src={url} alt={name} />}
          </div>
        </div>
      </a>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);",
    aspectRatio: "8.5 / 11", // This ensures a 8.5x11 aspect ratio
    width: "300px", // Ensure it stretches to fit the space while maintaining ratio
    backgroundColor: "white",
    justifyContent: "center",
    height: "100%",
    overflow: "hidden", // Ensure content fits within the container
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    gap: "16px",
    fontSize: "1.2vw", // Adjusted for responsive text size
  },
  image: {
    width: "80%", // Adjust image size relative to the container
    maxHeight: "200px", // Ensure image maintains its aspect ratio
    objectFit: "contain", // Scale the image properly inside the container
  },
  textName: {
    margin: 0,
    fontSize: "14px",
    fontFamily: "sans-serif",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  textMessage: {
    fontSize: "14px", // Adjust font size
    margin: 0,
    fontFamily: "sans-serif",
    textAlign: "center",
  },
  anchorStyle: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default Result;
