import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ArkComponent from "./Arks/Book/Ark";
import { arkContext, useArk } from "./Arks/Contexts/ArkContext";
import { Manifest, manifestContext } from "./Arks/Contexts/ManifestContext";
import HomeComponent from "./Arks/Home/HomeComponent";
import ArkManifestComponent from "./Arks/Manifest/ArkManifest";
import Passengers from "./Arks/Passengers/Passengers";
import ReviewComponent from "./Arks/Review/Review";
import Thanks from "./Arks/Thanks/Thanks";
import CommunityBoardForm from "./Donate/CommunityBoard";
import DonateComponent from "./Donate/DonateComponent";
import LandingPage from "./Donate/landingPage";
import ShockForm from "./Donate/Shock";
import ShowControllerView from "./Donate/Shock/ShockControllerVIew";
import Thankyou from "./Donate/thankyou";
import VideoDonation from "./Donate/Video";
import VoiceDonations from "./Donate/VoiceDonations";
import Linktree from "./Linktree";
import DonationFinder from "./Arks/DonationFinder/DonationFinder";
import PrintLeaderboard from "./Arks/DonationFinder/LeaderBoard";
import AllPrints from "./Arks/DonationFinder/AllPrints";

function App() {
  const ark = useArk();
  const [manifest, setManifest] = useState<Manifest>({
    name: "",
    trade: "",
    country: "US",
    item: "",
    bibleVerse: "",
    seat: undefined,
  });

  const queryClient = new QueryClient();

  const arkBase = "/ark";

  return (
    <QueryClientProvider client={queryClient}>
      <arkContext.Provider value={ark}>
        <manifestContext.Provider value={{ manifest, setManifest }}>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<LandingPage />} />
                <Route path="donate">
                  <Route path="print" element={<DonateComponent />} />
                  <Route path="communityboard" element={<CommunityBoardForm />} />
                  <Route path="thankyou" element={<Thankyou />} />
                  <Route path="voicedonations" element={<VoiceDonations />} />
                  <Route path="videodonation" element={<VideoDonation />} />
                  <Route path="shockdonation" element={<ShockForm />} />
                  <Route path="shock" element={<ShowControllerView />} />
                </Route>
                <Route path="ark">
                  <Route index element={<Passengers />} />
                  <Route path="book" element={<ArkComponent />} />
                  <Route path="manifest" element={<ArkManifestComponent />} />
                  <Route path="review" element={<ReviewComponent />} />
                  <Route path="thanks" element={<Thanks />} />
                  <Route path="home" element={<HomeComponent />} />
                </Route>
                <Route path={`linktree`} element={<Linktree />} />
                <Route path={`prints`} element={<DonationFinder />} />
                <Route path={`prints/leaders`} element={<PrintLeaderboard />} />
                <Route path={`prints/all`} element={<AllPrints />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </manifestContext.Provider>
      </arkContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
