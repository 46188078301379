import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Result from "./components/Result";
import configuration from "../../configuration";

// Types
interface TopPrint {
  donation_id: string;
  vote_count: number;
}

interface DonationData {
  id: number;
  name: string;
  message: string;
  url?: string;
  youtube_id: string;
  youtube_timestamp: number;
}

interface CombinedData extends DonationData {
  vote_count: number;
}

// Component
const PrintLeaderboard: React.FC = () => {
  const classes = useStyles();
  const [printData, setPrintData] = useState<CombinedData[]>([]); // Combined donation and vote data

  useEffect(() => {
    // Function to fetch the top prints data
    const fetchTopPrints = async () => {
      try {
        const topPrintsResponse = await axios.get<TopPrint[]>("https://prints.richardburnish.org/api/top-prints");
        const topPrints = topPrintsResponse.data; // Array of donation_id and vote_count
        console.log(topPrints);

        // Now fetch the donation data for each donation_id
        const fetchDonationData = async (donationId: string): Promise<DonationData> => {
          const donationResponse = await axios.get<DonationData>(`${configuration.apiUrl}/donations/${donationId}`);
          return donationResponse.data; // Return the donation data
        };

        // Create a list of promises to fetch all donation data and combine with vote_count
        const combinedData: CombinedData[] = await Promise.all(
          topPrints.map(async (item: TopPrint) => {
            const donationData = await fetchDonationData(item.donation_id);
            return { ...donationData, vote_count: item.vote_count }; // Combine vote_count with donation data
          })
        );

        setPrintData(combinedData); // Set the combined data in state
      } catch (error) {
        console.error("Error fetching print data:", error);
      }
    };

    fetchTopPrints();
  }, []);

  return (
    <div>
      <Helmet>
        {/* Page Title */}
        <title>Richard Burnish: Hall of Fame</title>

        {/* Inline CSS Styles */}
        <style>{`
          body {
            background-image: url(/images/background.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-attachment: fixed;
          }
        `}</style>

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Richard Burnish: Hall of Fame" />
        <meta
          property="og:description"
          content={
            "Dive into the Hall of Fame to see the finest prints that have earned top blessings. Celebrate with song for the best creations in the gallery."
          }
        />
        <meta property="og:url" content={"https://ark.richardburnish.org/prints/leaders"} />
        <meta property="og:site_name" content={"Richard Burnish: The Ark"} />
        <meta property="og:image" content={"https://ark.richardburnish.org/images/hof_og.jpg"} />
        <meta property="og:image:width" content={"1440"} />
        <meta property="og:image:height" content={"600"} />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.appBar}>
          <Link to="/prints">
            <p style={{ color: "white" }}>Search</p>
          </Link>
          <p
            style={{
              color: "#E7BC10",
              textShadow: "0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)",
              borderBottom: "1px solid #E7BC10",
            }}
          >
            Hall of Fame
          </p>
          <Link to="/prints/all">
            <p style={{ color: "white" }}>All Prints</p>
          </Link>
        </div>

        <div className={classes.formInnerContainer}>
          <img src="/images/logo.png" alt="Logo" />
          <h1
            style={{
              color: "#E7BC10",
              textShadow: "0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)",
              fontWeight: "black",
              cursor: "default",
              margin: 0,
            }}
          >
            HALL OF FAME
          </h1>
        </div>
        <div className={classes.resultGrid}>
          {printData.map((print: CombinedData) => (
            <div>
              <a
                style={{ color: "inherit", textDecoration: "inherit" }}
                href={`https://prints.richardburnish.org/print/${print.id}`}
              >
                <div className={classes.leaderItemContainer}>
                  <Result key={print.id} id={print.id} name={print.name} message={print.message} url={print.url} />
                  <div className={classes.leaderNamePlate}>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <h2>🙏 {print.vote_count}</h2>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Styles
const useStyles = makeStyles({
  appBar: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#331E46",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    color: "white",
    gap: 44,
    fontWeight: "bold",
  },
  leaderItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  leaderNamePlate: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    borderRadius: "4px",
    backgroundColor: "#9400ff",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 16px",
    position: "relative",
    top: "-96px",
    right: "-112px",
    // width: "100%",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    gap: 40,
  },
  innerContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "90%",
  },
  formInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    boxShadow: "0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);",
    marginBottom: "24px",
    paddingTop: "24px",
    paddingBottom: "24px",
    minWidth: "400px",
    backgroundColor: "#331e46cc",
    color: "white",
    gap: 16,
  },
  resultGrid: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 24,
    marginBottom: "32px",
  },
});

export default PrintLeaderboard;
