import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Button,
} from "@material-ui/core";
import axios from "axios";
import configuration from "../../configuration";
import Result from "./components/Result";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

interface ResultData {
  name: string;
  message: string;
  url: string;
  id: number;
}

const NoResult: React.FC = () => (
  <div style={{ color: "white", textAlign: "center", marginTop: "20px" }}>
    <h3>No results found.</h3>
    <p>Please try a different search query.</p>
  </div>
);

const DonationFinder: React.FC = () => {
  const classes = useStyles();
  const [type, setType] = useState<string>("name");
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<ResultData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false); // New state variable

  // Function to read the URL parameters and fetch results
  const handleURLChange = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const typeParam = searchParams.get("type") || "name";
    const queryParam = searchParams.get("q") || "";
    const pageParam = parseInt(searchParams.get("page") || "1", 30);

    // Only update state and fetch results if the query, type, or page has changed
    if (queryParam !== query || typeParam !== type || pageParam !== page) {
      setType(typeParam);
      setQuery(queryParam);
      setPage(pageParam);
      setResults([]);
      if (queryParam) {
        fetchResultsUpToPage(pageParam, queryParam, typeParam);
        setHasSearched(true); // Set hasSearched to true
      }
    }
  };

  // useEffect to handle component mount and URL changes
  useEffect(() => {
    handleURLChange();

    const handlePopState = () => {
      handleURLChange();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to restore scroll position
  useEffect(() => {
    if (!scrolled && results.length > 0) {
      const storedScrollPosition = sessionStorage.getItem("donationFinderScrollPosition");
      if (storedScrollPosition) {
        window.scrollTo(0, parseInt(storedScrollPosition, 30));
        sessionStorage.removeItem("donationFinderScrollPosition");
        setScrolled(true);
      }
    }
  }, [results, scrolled]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPage(1);
    setResults([]);
    setScrolled(false); // Reset scrolled state
    setHasSearched(true); // Set hasSearched to true
    fetchResults(1, query, type);

    // Update URL
    const newUrl = `${window.location.pathname}?type=${type}&q=${encodeURIComponent(query)}&page=1`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const fetchResults = async (pageNumber: number, query: string, type: string) => {
    setLoading(true);
    try {
      const response = await axios.get(`${configuration.apiUrl}/donations/faxfinder`, {
        params: {
          type,
          q: query,
          page: pageNumber,
        },
      });
      setResults((prevResults) => [...prevResults, ...response.data]);
      setHasMore(response.data.length === 30);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch results up to a specific page
  const fetchResultsUpToPage = async (maxPage: number, query: string, type: string) => {
    setLoading(true);
    const allResults: ResultData[] = [];
    try {
      for (let currentPage = 1; currentPage <= maxPage; currentPage++) {
        const response = await axios.get(`${configuration.apiUrl}/donations/faxfinder`, {
          params: {
            type,
            q: query,
            page: currentPage,
          },
        });
        allResults.push(...response.data);
        // Update hasMore based on the last response
        if (currentPage === maxPage) {
          setHasMore(response.data.length === 30);
        }
      }
      setResults(allResults);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchResults(nextPage, query, type);

    // Update URL with new page number
    const newUrl = `${window.location.pathname}?type=${type}&q=${encodeURIComponent(query)}&page=${nextPage}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  return (
    <div className={classes.container}>
      <div className={classes.appBar}>
        {/* <img src="/images/logo.png" style={{ height: "48.33px" }} /> */}
        <p
          style={{
            color: "#E7BC10",
            textShadow: "0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)",
            cursor: "default",
            borderBottom: "1px solid #E7BC10",
          }}
        >
          Search
        </p>
        <Link to="/prints/leaders">
          <p style={{ color: "white", textDecoration: "none" }}> Hall of Fame</p>
        </Link>
        <Link to="/prints/all">
          <p style={{ color: "white" }}>All Prints</p>
        </Link>
      </div>
      <Helmet>
        {/* Page Title */}
        <title>Richard Burnish: The Print Vault</title>

        {/* Inline CSS Styles */}
        <style>{`
          body {
            background-image: url(/images/background.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-attachment: fixed;
          }
        `}</style>

        {/* Open Graph Meta Tags */}
        <meta
          name="Richard Burnish - Print Valut"
          content="Welcome to the Print Vault! Search through every print, watch all prints on YouTube, and vote for your favorite prints. Explore and support the love that supports this community."
        />
        <meta property="og:image" content={"https://ark.richardburnish.org/images/vault_og.jpg"} />
        <meta property="og:image:width" content={"1440"} />
        <meta property="og:image:height" content={"600"} />
        <meta property="og:url" content={"https://ark.richardburnish.org/prints/"} />
        <meta property="og:site_name" content={"Richard Burnish: The Ark"} />
        <meta
          property="og:description"
          content={
            "Welcome to the Print Vault! Search through every print, watch all prints on YouTube, and vote for your favorite prints. Explore and support the love that supports this community."
          }
        />
      </Helmet>
      <div className={classes.innerContainer}>
        <form onSubmit={handleSubmit} className={classes.formInnerContainer}>
          <img src="/images/logo.png" alt="Logo" />
          <FormControl style={{ gap: 8 }}>
            <FormLabel style={{ color: "white" }}>Search type:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={type}
              onChange={handleTypeChange}
            >
              <FormControlLabel value="message" control={<Radio />} label="Message Search" />
              <FormControlLabel value="name" control={<Radio />} label="Name Search" />
            </RadioGroup>
            <TextField
              fullWidth
              id="outlined-basic"
              label={`${type} Search`}
              variant="filled"
              value={query}
              autoComplete="off"
              autoCorrect="off"
              className={classes.customTextField}
              onChange={handleQueryChange}
              style={{ textTransform: "capitalize" }}
              type="search"
              inputProps={{
                enterKeyHint: "search", // Added this line
              }}
            />
          </FormControl>
        </form>
        <div className={classes.resultGrid}>
          {hasSearched ? (
            results.length > 0 ? (
              results.map((result, index) => (
                <Result key={index} id={result.id} name={result.name} message={result.message} url={result.url} />
              ))
            ) : (
              <NoResult />
            )
          ) : null}
        </div>
        {hasMore && results.length > 0 && (
          <Button onClick={handleLoadMore} variant="contained" color="primary" disabled={loading}>
            {loading ? "Loading..." : "Load More"}
          </Button>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  appBar: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#331E46",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    color: "white",
    gap: 44,
    fontWeight: "bold",
  },
  test: {
    height: "92vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    gap: 40,
  },
  innerContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "90%",
  },
  formInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    boxShadow: "0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);",
    marginBottom: "24px",
    paddingTop: "24px",
    paddingBottom: "24px",
    minWidth: "400px",
    backgroundColor: "#331e46cc",
    color: "white",
    gap: 16,
  },
  resultGrid: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 16,
    marginBottom: "32px",
  },
  customTextField: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white", // Set background color to white
    },
    "& .MuiInputLabel-root": {
      color: "black", // Set label color
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "black", // Set underline color
    },
  },
});

export default DonationFinder;
