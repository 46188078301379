import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import axios from "axios";
import configuration from "../../configuration";
import Result from "./components/Result";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

interface ResultData {
  name: string;
  message: string;
  url: string;
  id: number;
}

const NoResult: React.FC = () => (
  <div style={{ color: "white", textAlign: "center", marginTop: "20px" }}>
    <h3>No results found.</h3>
    <p>Please try a different search query.</p>
  </div>
);

const AllPrints: React.FC = () => {
  const classes = useStyles();
  const [results, setResults] = useState<ResultData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  // Fetch results on load with default parameters
  useEffect(() => {
    const fetchInitialResults = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${configuration.apiUrl}/donations/faxfinder`, {
          params: {
            type: "name",
            q: "",
            page: 1,
          },
        });
        setResults(response.data);
        setHasMore(response.data.length === 30); // Check if more pages exist
        setHasSearched(true); // Indicates that an initial search has been performed
      } catch (error) {
        console.error("Error fetching initial results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialResults();
  }, []);

  // Restore scroll position after fetching results
  useEffect(() => {
    if (!scrolled && results.length > 0) {
      const storedScrollPosition = sessionStorage.getItem("donationFinderScrollPosition");
      if (storedScrollPosition) {
        window.scrollTo(0, parseInt(storedScrollPosition, 10));
        sessionStorage.removeItem("donationFinderScrollPosition");
        setScrolled(true);
      }
    }
  }, [results, scrolled]);

  const fetchResults = async (pageNumber: number) => {
    setLoading(true);
    try {
      const response = await axios.get(`${configuration.apiUrl}/donations/faxfinder`, {
        params: {
          type: "name",
          q: "",
          page: pageNumber,
        },
      });
      setResults((prevResults) => [...prevResults, ...response.data]);
      setHasMore(response.data.length === 30); // Determine if more results exist
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchResults(nextPage);

    // Update URL with new page number
    const newUrl = `${window.location.pathname}?type=name&q=&page=${nextPage}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  return (
    <div className={classes.container}>
      <div className={classes.appBar}>
        <Link to="/prints">
          <p style={{ color: "white", textDecoration: "none" }}>Search</p>
        </Link>
        <Link to="/prints/leaders">
          <p style={{ color: "white", textDecoration: "none" }}>Hall of Fame</p>
        </Link>
        <p
          style={{
            color: "#E7BC10",
            textShadow: "0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)",
            cursor: "default",
            borderBottom: "1px solid #E7BC10",
          }}
        >
          All Prints
        </p>
      </div>
      <Helmet>
        {/* Page Title */}
        <title>Richard Burnish: The Print Vault</title>

        {/* Inline CSS Styles */}
        <style>{`
          body {
            background-image: url(/images/background.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-attachment: fixed;
          }
        `}</style>

        {/* Open Graph Meta Tags */}
        <meta
          name="Richard Burnish - Print Valut"
          content="Welcome to the Print Vault! Search through every print, watch all prints on YouTube, and vote for your favorite prints. Explore and support the love that supports this community."
        />
        <meta property="og:image" content={"https://ark.richardburnish.org/images/vault_og.jpg"} />
        <meta property="og:image:width" content={"1440"} />
        <meta property="og:image:height" content={"600"} />
        <meta property="og:url" content={"https://ark.richardburnish.org/prints/"} />
        <meta property="og:site_name" content={"Richard Burnish: The Ark"} />
        <meta
          property="og:description"
          content={
            "Welcome to the Print Vault! Search through every print, watch all prints on YouTube, and vote for your favorite prints. Explore and support the love that supports this community."
          }
        />
      </Helmet>
      <div className={classes.innerContainer}>
        <div className={classes.formInnerContainer}>
          <img src="/images/logo.png" alt="Logo" />
          <h1
            style={{
              color: "#E7BC10",
              textShadow: "0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)",
              fontWeight: "black",
              cursor: "default",
              margin: 0,
            }}
          >
            ALL PRINTS
          </h1>
        </div>
        <div className={classes.resultGrid}>
          {hasSearched ? (
            results.length > 0 ? (
              results.map((result, index) => (
                <Result key={index} id={result.id} name={result.name} message={result.message} url={result.url} />
              ))
            ) : (
              <NoResult />
            )
          ) : null}
        </div>
        {hasMore && results.length > 0 && (
          <Button onClick={handleLoadMore} variant="contained" color="primary" disabled={loading}>
            {loading ? "Loading..." : "Load More"}
          </Button>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  appBar: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#331E46",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    color: "white",
    gap: 44,
    fontWeight: "bold",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    gap: 40,
  },
  innerContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "90%",
  },
  formInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    boxShadow: "0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);",
    marginBottom: "24px",
    paddingTop: "24px",
    paddingBottom: "24px",
    minWidth: "400px",
    backgroundColor: "#331e46cc",
    color: "white",
    gap: 16,
  },
  resultGrid: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 16,
    marginBottom: "32px",
  },
});

export default AllPrints;
